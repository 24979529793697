<template>
    <div class="container">
      <div class="loader" v-if="!showBtn">
        <div class="ml-loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="loading_title" v-if="!showBtn">货源正在飞奔而来, 请稍后</div>
      <div v-if="goodsDetails" :class="!showBtn ? 'all_mask':''">
        <div class="title">
          <div class="title_container">
            <img class="horn" src="@/assets/image/horn.png" alt="">
            <div class="title_content">
              <div class="inner">
                <span>{{radio}}</span>
                <span>{{radio}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="card">
            <img class="left_top_bg" src="@/assets/image/left_top_bg.png" alt="">
            <img class="right_bottom_bg" src="@/assets/image/right_bottom_bg.png" alt="">
            <div class="address_container">
              <div class="address">
                <div class="address_left">
                  {{goodsDetails.handleAddrArr[0]}}
                </div>
                <div class="address_arrow">
                  <img src="@/assets/image/arrow.png" alt="">
                </div>
                <div class="address_right">
                  {{goodsDetails.handleAddrArr[1]}}
                </div>
              </div>
              <div class="goods_info">
                <img class="icon1" src="@/assets/image/icon1.png" alt="">
                <div class="info">
                  <div>
                    <!-- <span :class="[goodsDetails.cargoChild.goodsTypeVO[0].typeName=='绿通'?'tag':goodsDetails.cargoChild.goodsTypeVO[0].typeName=='大宗'?'tag1':'tag2']" v-if="goodsDetails.cargoChild.goodsTypeVO[0].typeName == '绿通'">绿通</span> -->
                    <span><span>
                      {{goodsDetails.matName}}
                      </span>
                      <span>{{goodsDetails.unit}}{{goodsDetails.unitName}}</span>
                      {{goodsDetails.isBidding == "1" ? '竞价' : '固价'}}</span>
                  </div>
                </div>
              </div>
              <div class="goods_info">
                <img class="icon2" src="@/assets/image/icon2.png" alt="">
                <div class="info">
                  <div class=""> 
                    <span>{{showItemCar(goodsDetails)}}</span>
                    <span>{{showItemLenngth(goodsDetails)}}</span>
                    &nbsp;&nbsp;&nbsp; {{goodsDetails.loadMethodName ? goodsDetails.loadMethodName : ''}}
                  </div>
                </div>
              </div>
              <div class="goods_price">
                <div class="price_left">
                  <div class="price_left_1">
                  </div>
                  <div class="price_left_2">
                    
                  </div>
                </div>
                <div class="price_right" v-if="goodsDetails.fixPriceFlag == '1'" >
                  <span class="right_symbol">¥</span>
                  <span
                    class="right_price">{{goodsDetails.fixPriceFlag == "1" ? $decimal.accDiv(goodsDetails.fixPrice,100) : $decimal.accDiv(goodsDetails.child.orderQuoteVO.quotePrice,100)}}</span>
                  <span class="right_unit">/{{goodsDetails.child.orderQuoteVO.quotePriceTypeName}}</span>
                </div>
                <div class="prompt-box" v-else>
                  <div>点击下方按钮</div>
                  <div style="padding-top: 5px">立即查看</div>
                </div>
              </div>
              <div class="line_container">
                <div class="left_round"></div>
                <div class="line"></div>
                <div class="right_round"></div>
              </div>
              <div class="go_app">
                <div class="go_app_left">
                  <div class="go_app_text">点击右侧按钮打开APP</div>
                  <div class="go_app_text">查看货源详情</div>
                  <div class="go_app_line"></div>
                  <div class="go_app_content">
                    <span>万年青货运</span>
                  </div>
                </div>
                <div class="go_app_right" v-if="!isWeixin" @click="toAppStore">
                  <img style="width:73px;height: 73px; " src="https://hyzg-app.oss-cn-beijing.aliyuncs.com/btn-share.png" alt="">
                </div>
                <div v-else>
                  <wx-open-launch-app id="launch-btn" class="go_app_right" @error="handleErrorFn" @launch="handleLaunchFn"
                  style="width:73px;height: 73px;display:block;position:relative;"
                    appid="wx4131994e0ff6a425" :extinfo="extInfo">
                    <script type="text/wxtag-template">
                      <style>.btn { display: flex;align-items: center;width:73px;height: 73px; }</style>								
                    <div  class="btn">
                      <img  style="width:73px;height: 73px;" src="https://hyzg-app.oss-cn-beijing.aliyuncs.com/btn-share.png" alt="">
                    </div>					
                  </script>
                  </wx-open-launch-app>
                </div>
              
              </div>
          
            </div>
  
            <img class="rocket" src="@/assets/image/rocket.png" alt="">
          </div>
          <div class="msak">
            <div class="bottom-btn" v-if="isWeixin">
              <wx-open-launch-app id="launch-btn2" @error="handleErrorFn" @launch="handleLaunchFn"
              style="width:200px;height: 50px;display:block;position:relative;" 
                appid="wx4131994e0ff6a425" :extinfo="extInfo1">
                <script type="text/wxtag-template">
                  <style>.btn { margin-top: 20px;color: #FFFFFF;background: #FF8F35;border-radius: 6px;height: 50px;width: 200px;text-align:center;line-height: 50px;box-shadow: 0 1px 4px 0 #BA580A; }</style>								
                  <div class="btn">
                    点我查看更多货源→
                  </div>					
                </script>
              </wx-open-launch-app>
            </div>
            <div class="bottom-btn" v-else>
              <div class="btn2" @click="toStoreList">
                点我查看更多货源→
              </div>		
            </div>
            <div class="bottom_content">
              <div class="bottom_text">
                <!-- <div class="">注册就送<span>300</span>元加油抵用券！</div>
                <div class="">接单再送定制卫衣！</div> -->
                <!-- <div class="">注册就送<span>300</span>元加油抵用券！</div> -->
                <div class=""></div>
              </div>
            </div>
            <img class="bottom_image_left" src="@/assets/image/red_envelopes1.png" alt="">
            <img class="bottom_image_right" src="@/assets/image/red_envelopes2.png" alt="">
          </div>
        </div>
      </div>
    </div>  
  </template>
  <script>
    import {
        getSignWnq,
      getOrderDetailsWnq,
    } from "@/request/apiWnqShare.js"
    import wx from 'weixin-js-sdk'

    export default {
      data() {
        return {
          extInfo: "",
          extInfo1: 'cargoList?index',
          isWeixin: true,
          goodsDetails: null,
          browseNum: Math.floor(Math.random() * (10 - 5 + 1) + 5),
          offerNum: Math.floor(Math.random() * (500 - 200 + 1) + 200),
          mList: [],
          radio: '',
          isiOS: '',
          orderList: [],
          showBtn: false,
          locationParams: {}
        }
      },
      mounted() {
        let that = this;
        var ua = navigator.userAgent.toLowerCase();
        that.isWeixin = ua.indexOf('micromessenger') != -1; //判断是否是微信浏览器
        var u = navigator.userAgent;
        that.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        // if (that.isWeixin) {
        that.getDetails()
        // window.open(`https://www.hyzgapp.com/akpdownload/hyzgappdownload.html`)
        // return true;
        // } else {
        //   // return false;
        // }

        document.addEventListener('WeixinOpenTagsError', function (e) {
          console.error(e.detail.errMsg); // 无法使用开放标签的错误原因，需回退兼容。仅无法使用开放标签，JS-SDK其他功能不受影响
        });

      
      },
      methods: {
        toStoreList() {
          //跳转App首页
          let that = this;
           
            that.opening = true
            //如果应用安装，则跳转应用，否则下载
            var isAndroid = this.isCpu("android");
            var isIos = this.isCpu("ios");
            var extInfo = this.extInfo1;
            if (isAndroid) {
              window.open("wnq://" + extInfo,'_self');
              that.timer = setTimeout(()=>{
                that.opening = false;
                let hidden=window.document.hidden||window.document.webkitHidden||window.document.mozHidden||window.document.msHidden
                if(typeof(hidden) =="undefined"||hidden==false){
                  window.location.href="https://www.jxwnqwl.com/wnqdownload/"
                }
              },1000)
            } else if (isIos) {
              //如果是ios的跳转
              // window.location = "hyzg://" + extInfo;
              // that.timer = setTimeout(function () { //  未安装的情况,跳转appstore
              //   that.opening = false
              //   //  跳转app store
              //   window.location = 'https://apps.apple.com/cn/app/%E8%B4%A7%E8%BF%90%E4%B8%AD%E5%9B%BD/id1566995266'
              // }, 1000);
              window.location.href = "https://www.jxwnqwl.com/wnqdownload/";
              
              that.openApp("wnq://" + extInfo);
            }
        },
        toAppStore() {
            let that = this;
            that.opening = true
            //如果应用安装，则跳转应用，否则下载
            var isAndroid = this.isCpu("android");
            var isIos = this.isCpu("ios");
            var extInfo = this.extInfo;
            if (isAndroid) {
              window.open("wnq://" + extInfo,'_self');
              that.timer = setTimeout(()=>{
                that.opening = false;
                let hidden=window.document.hidden||window.document.webkitHidden||window.document.mozHidden||window.document.msHidden
                if(typeof(hidden) =="undefined"||hidden==false){
                  window.location.href="https://www.jxwnqwl.com/wnqdownload/"
                }
              },1000)
            } else if (isIos) {
              //如果是ios的跳转
              // window.location = "hyzg://" + extInfo;
              // that.timer = setTimeout(function () { //  未安装的情况,跳转appstore
              //   that.opening = false
              //   //  跳转app store
              //   window.location = 'https://apps.apple.com/cn/app/%E8%B4%A7%E8%BF%90%E4%B8%AD%E5%9B%BD/id1566995266'
              // }, 1000);
              window.location.href = "https://www.jxwnqwl.com/wnqdownload/";
              
              that.openApp("wnq://" + extInfo);
            }
        },
        openApp(src) {
        // 通过iframe的方式试图打开APP，如果能正常打开，会直接切换到APP，并自动阻止a标签的默认行为
        // 否则打开a标签的href链接
          var ifr = document.createElement('iframe');
          ifr.src = src;
          ifr.style.display = 'none';
          document.body.appendChild(ifr);
          window.setTimeout(function(){
                document.body.removeChild(ifr);
          },1000);
      },
        isWx(){  
          var ua = navigator.userAgent.toLowerCase();  
          console.log("ua", ua);
          console.log("正则", ua.match(/MicroMessenger/i))
          if(ua.match(/MicroMessenger/i) == "micromessenger") {  
              return true;  
          } else {  
              return false;  
          }  
        },
        isCpu(type) {
          let ran = navigator.userAgent
          let isAndroid = ran.indexOf('Android') > -1 || ran.indexOf('Linux') > -1
          let isIOS = !!ran.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
          
          if (isAndroid && type == "android") {
              // 安卓代码块
              return true
          } else if (isIOS && type == "ios") {
              // ios代码块
              return true
          } else {
            return false
          }
        },
        download() {
          window.open('https://www.jxwnqwl.com/wnqdownload/')
        },
        async initWechat() {
          let that = this;
          var href = location.href.split('#')[0];
          href = encodeURIComponent(href);
          let res = await getSignWnq({
            url: href
          })
          if(res.data.retCode == "0000000"){
            
            let signObj = res.data.rspBody
            signObj.timestamp = res.data.rspBody.timestamp
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
              appId: 'wxdb42ec98025d7947', // 必填，公众号的唯一标识
              timestamp: signObj.timestamp, // 必填，生成签名的时间戳
              nonceStr: signObj.noncestr, // 必填，生成签名的随机串
              signature: signObj.signature, // 必填，签名
              jsApiList: ['wx-open-launch-app'], // 必填，需要使用的JS接口列表
              openTagList: ['wx-open-launch-app'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
            });
            wx.ready(() => {
              that.showBtn = true;
              that.init()
            });
            var btn = document.getElementById('launch-btn');
            btn.addEventListener('launch', function () {
            });
            btn.addEventListener('error', function () {
              window.open(
                  'https://www.jxwnqwl.com/wnqdownload/')
              
            });
            var btn2 = document.getElementById('launch-btn2');
            btn2.addEventListener('launch', function () {
            });
            btn2.addEventListener('error', function () {
              
              window.open(
                  'https://www.jxwnqwl.com/wnqdownload/')
              
            });
            wx.error((err) => {
              console.log(err, 'error'); // 如果报错打印报错信息
            });
          }else if(res.retCode == '500') {
              return;
          }
          
        },
        showSenderCity(details) {
          var cargoRuaddressVO = details.cargoRuaddressVO;
            var item = cargoRuaddressVO[0];
            let obj = {
                province: item.senderProvince,
                
                city: item.senderCity,
            
                area: item.senderArea,
                
            }
                  return this.showAddressDetail(obj);
        },
        showReceiverCity(details) { // 目的地
          var cargoRuaddressVO = details.cargoRuaddressVO;
            var item = cargoRuaddressVO[0];
            let obj = {
                province: item.receiverProvince,
                
                city: item.receiverCity,
            
                area: item.receiverArea,
                
            }
                  return this.showAddressDetail(obj);
              },
        showAddressDetail(obj) { // 展示地区
          if(obj.province == '上海市' || obj.province == '北京市' || obj.province == '重庆市' || obj.province == '天津市') {
                      if(obj.province == obj.city) {
                          return obj.province;
                      }else {
                          let returnMsg = obj.province;
                          returnMsg = obj.city ? returnMsg + ' ' + obj.city : returnMsg;
                          return returnMsg;
                      }
                  }else {
                      if(!obj.city) {
                          return obj.province
                      }else if(obj.city == obj.area) {
                          return obj.city;
                      }else {
                          let returnMsg = obj.city;
                          returnMsg = obj.area ? returnMsg + ' ' + obj.area : returnMsg;
                          return returnMsg;
                      }
                  }
              },
  
        getDetails() {
          let that = this;
          let params = that.getArgs();
          that.locationParams = params
          localStorage.setItem('token', params.token)
          getOrderDetailsWnq({
            id: params.orderNumber
          }).then(res => {
            console.log(res, "Res")
            if (res.data.retCode == "0000000") {
              let details = res.data.rspBody;
              that.extInfo = "cargoDetail?" + `${details.id}`
              let startAddress = this.showSenderCity(details);
              let endAddress = this.showReceiverCity(details);
              var reg1 = new RegExp("省", "g");
              var reg2 = new RegExp("市", "g");
              var reg3 = new RegExp("区", "g");
              startAddress = startAddress.replace(reg1, "");
              startAddress = startAddress.replace(reg2, "");
              startAddress = startAddress.replace(reg3, "");
              endAddress = endAddress.replace(reg1, "");
              endAddress = endAddress.replace(reg2, "");
              endAddress = endAddress.replace(reg3, "");

              details.handleAddrArr = [startAddress, endAddress];

              that.goodsDetails = details;
            
              var isWx = that.isWx();
              console.log("isWx", isWx);
              if (isWx) {
                that.initWechat()
              } else {
                that.showBtn = true;
              }
              
            } else {
              that.$message.error(res.data.retDesc)
            }
              
          })
        },
        init() {
          let that = this;
          let mList = []
          let lastnameList = ['赵', '钱', '孙', '李', '周', '吴', '郑', '王',
            '冯', '陈', '褚', '卫', '蒋', '沈', '韩', '杨',
            '朱', '秦', '尤', '许', '何', '吕', '施', '张',
            '孔', '曹', '严', '华', '金', '魏', '陶', '姜',
            '戚', '谢', '邹', '喻', '柏', '水', '窦', '章',
            '云', '苏', '潘', '葛', '奚', '范', '彭', '郎',
            '鲁', '韦', '昌', '马', '苗', '凤', '花', '方',
            '俞', '任', '袁', '柳', '酆', '鲍', '史', '唐',
            '费', '廉', '岑', '薛', '雷', '贺', '倪', '汤',
            '滕', '殷', '罗', '毕', '郝', '邬', '安', '常',
            '乐', '于', '时', '傅', '皮', '卞', '齐', '康',
            '伍', '余', '元', '卜', '顾', '孟', '平', '黄',
            '和', '穆', '萧', '尹', '姚', '邵', '湛', '汪',
            '祁', '毛', '禹', '狄', '米', '贝', '明', '臧',
          ]
          for (let i = 0; i < 5; i++) {
            let obj = {
              minute: Math.floor(Math.random() * (60 - 1 + 1) + 1),
              lastname: lastnameList[Math.floor(Math.random() * (lastnameList.length + 1))]
            }
            mList.push(obj)
          }
          that.mList = mList
     
        },
        handleErrorFn(e) {
          this.$data.wechatOpenAppData = "【这里是error 函数】" + JSON.stringify(e)
          console.log(e)
        },
        handleLaunchFn() {
          
        },
        getArgs() {
          let args = {};
          let query = location.href.split("?")[1]
          if (query) {
            let pairs = query.split("&");
            pairs.forEach(item => {
              let key = item.split('=')[0]
              let value = item.split('=')[1]
              args[key] = value
            })
          }
  
          return args
        },
        showItemLenngth(item) { // 展示车长
          let returnMsg = item.vehicleLengthName ? item.vehicleLengthName : '';
                  return returnMsg;
              },
              showItemCar(item) { // 展示车型
                let returnMsg = item.vehicleBoxName ? item.vehicleBoxName : '';
                  return returnMsg;
              },
      }
    }
  
  </script>
  <style>
    .goods_bg {
      width: 100%;
    }
    .all_mask{
      -webkit-filter: blur(0.02rem);
      -moz-filter: blur(0.02rem);
      -ms-filter: blur(0.02rem);
      -o-filter: blur(0.02rem);
      filter: blur(0.02rem);
    }
    .bottom-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      display: none;
    }
  
    @keyframes scaleDraw {
  
      /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
      0% {
        transform: scale(1);
        /*开始为原始大小*/
      }
  
      25% {
        transform: scale(1.2);
        /*放大1.1倍*/
      }
  
      50% {
        transform: scale(1);
      }
  
      75% {
        transform: scale(1.2);
      }
    }
  
  </style>
  <style lang="scss" scoped>
    .btn { font-size: 14px;margin-top: 20px;color: #FFFFFF;height: 73px;width: 73px;text-align:center;}
    .btn2 {
      font-size: 14px;
      margin-top: 20px;color: #FFFFFF;background: #FF8F35;border-radius: 6px;height: 50px;width: 200px;text-align:center;line-height: 50px;box-shadow: 0 1px 4px 0 #BA580A;
    }
    .down {
      font-size: 0.2rem;
      height: 100vh;
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .down_text {
      color: #FFFFFF;
    }
  
    .notice {
      height: 24px;
      line-height: 24px;
      white-space: nowrap;
      font-size: 14px;
      transform: translate3d(0, 0, 0);
  
    }
  
    .inner {
      display: inline-block;
      animation: moveAni 15s infinite linear normal;
    }
  
    @keyframes moveAni {
      0% {
        transform: translateX(0);
      }
  
      100% {
        transform: translateX(-50%);
      }
    }
  
    body {
      margin: 0;
      padding: 0;
    }
  
    .container {
      position: relative;
      width: 100vw;
      padding-bottom: 1rem;
      /* background: linear-gradient(180deg, #74C1FD 0%, #4AA2F3 100%); */
      background: #5badf5;
      overflow-x: hidden;
    }
  
  
    .title {
      display: flex;
      justify-content: center;
    }
  
    .title_container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .horn {
      width: 0.63rem;
      height: 0.6rem;
    }
  
    .title_content {
      // margin-left: 0.1rem;
      margin-right: 0.3rem;
      font-size: 0.16rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #E9F7FF;
      width: 2.2rem;
      overflow: hidden;
      white-space: nowrap;
      transform: translate3d(0, 0, 0);
    }
  
    .content {
      width: 100%;
      padding: 0 0.15rem;
      box-sizing: border-box;
    }
  
    .card {
      position: relative;
      background: #FFFFFF;
      box-shadow: 0rem 0.04rem 0.05rem 0rem rgba(6, 53, 95, 0.12), 0rem 0.03rem 0.08rem 0rem rgba(180, 219, 243, 0.39);
      border-radius: 0.05rem;
      height: 3.79rem;
    }
  
    .address {
      display: flex;
      padding: 0.2rem 0.13rem;
      box-sizing: border-box;
      align-items: center;
      justify-content: space-between;
    }
  
    .address_left {
      font-size: 0.22rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      display: inline-block;
    }
  
    .font_size {
      font-size: 0.18rem !important;
      height: 0.2rem;
      line-height: 0.2rem;
    }
  
    .address_arrow {
      font-size: 0.1rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #F29347;
    }
  
    .address_arrow img {
      width: 0.84rem;
      height: 0.1rem;
    }
  
    .address_right {
      font-size: 0.22rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      display: inline-block;
    }
  
    .goods_info {
      display: flex;
      padding: 0 0.12rem;
    }
  
    .icon1 {
      width: 0.13rem;
      height: 0.15rem;
      margin-top: 0.03rem;
    }
  
    .icon2 {
      margin-top: 0.03rem;
      width: 0.15rem;
      height: 0.13rem;
    }
  
    .info {
      margin-left: 0.16rem;
      font-size: 0.13rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  
    .info div {
      min-height: 0.25rem;
      line-height: 0.2rem;
    }
    .info-child{
      display: flex;
      align-items: center;
    }
    .info-child div{
      margin-right: 0.05rem;
    }
  
    .tag {
      padding: 0 0.07rem;
      color: #55DF43;
      border-radius: 0.1rem;
      border: 0.01rem solid #55DF43;
    }
    .tag1 {
      padding: 0 0.07rem;
      color: #0171F9;
      border-radius: 0.1rem;
      border: 0.01rem solid #0171F9;
    }
    .tag2 {
      padding: 0 0.07rem;
      color: #333333;
      border-radius: 0.1rem;
      border: 0.01rem solid #333333;
    }
  
    .goods_price {
      display: flex;
      margin-top: 0.2rem;
      padding: 0 0.12rem;
      justify-content: space-between;
    }
  
    .price_left {}
  
    .peo_number {
      font-size: 0.12rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6B6B6B;
    }
  
    .price_right {
      color: #F29347;
      display: flex;
      // margin-left: 0.6rem;
      margin-right: 0.1rem;
      align-items: baseline;
    }
  
    .right_symbol {
      font-size: 0.2rem;
    }
  
    .right_price {
      font-size: 0.25rem;
      // font-weight: bold;
    }
  
    .right_unit {
      font-size: 0.18rem;
    }
  
    .icon3 {
      margin-top: 0.03rem;
      width: 0.13rem;
      height: 0.16rem;
    }
  
    .price_left_1 {
      display: flex;
      align-items: center;
    }
  
    .price_left_1 div {
      margin-left: 0.16rem;
    }
  
    .price_left_2 {
      display: flex;
      align-items: center;
      margin-top: 0.1rem;
    }
  
    .price_left_2 div {
      margin-left: 0.16rem;
    }
  
    .icon4 {
      width: 0.13rem;
    }
  
    .line_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .line {
      border-top: 0.01rem dashed #DDE5EF;
      height: 0.01rem;
      overflow: hidden;
      width: 100%;
      padding: 0 0.1rem;
      box-sizing: border-box;
    }
  
    .left_round {
      width: 0.3rem;
      height: 0.3rem;
      background: #5badf5;
      border-radius: 50%;
      margin-left: -0.15rem;
    }
  
    .right_round {
      width: 0.3rem;
      height: 0.3rem;
      background: #5badf5;
      border-radius: 50%;
      margin-right: -0.15rem;
    }
  
    .go_app {
      padding: 0.1rem 0.12rem;
      display: flex;
      width: 100%;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;
    }
  
    .go_app_text {
      font-size: 0.14rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 0.2rem;
    }
  
    .go_app_line {
      margin-top: 0.1rem;
      width: 0.94rem;
      height: 0.01rem;
      background: #AAAAAA;
    }
  
    .go_app_content {
      margin-top: 0.1rem;
      display: flex;
      justify-content: flex-start;
      font-size: 0.12rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 0.17rem;
    }
  
    .go_app_content span {
      margin-right: 0.1rem;
    }
    .go_app_right_top{
      width: 0.86rem;
      height: 0.86rem;
    }
    .go_app_right {
      width: 0.86rem;
      height: 0.86rem;
      animation-name: scaleDraw;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-duration: 2s;
    }
  
    .content_bottom_title {
      margin-top: 0.18rem;
      margin-bottom: 0.12rem;
      font-size: 0.18rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 0.25rem;
      text-shadow: 0px 0px 0px rgba(12, 88, 171, 0.5);
    }
  
    .content_bottom_title span {
      color: #F29347;
    }
  
    .goods_source_item {
      position: relative;
      height: 1.5rem;
      background: #FFFFFF;
      box-shadow: 0rem 0.03rem 0.08rem 0rem rgba(180, 219, 243, 0.39);
      border-radius: 0.05rem;
      padding-bottom: 0.1rem;
      margin-bottom: 0.12rem;
    }
  
    .goods_source_price {
      position: absolute;
      z-index: 1;
      right: 0.19rem;
      bottom: 0.14rem;
      display: flex;
      color: #F29347;
      align-items: baseline;
      justify-content: flex-start;
    }
    .offer-text{
      font-size: 0.15rem;
      color: #F29347;
    }
  
    .filter_1 {
      -webkit-filter: blur(0.01rem);
      -moz-filter: blur(0.01rem);
      -ms-filter: blur(0.01rem);
      -o-filter: blur(0.01rem);
      filter: blur(0.01rem);
    }
  
    .filter {
      -webkit-filter: blur(0.03rem);
      -moz-filter: blur(0.03rem);
      -ms-filter: blur(0.03rem);
      -o-filter: blur(0.03rem);
      filter: blur(0.03rem);
    }
  
    .msak {
      position: absolute;
      z-index: 1;
      width: 100%;
      bottom: 0;
      padding-bottom: 0.4rem;
    }
  
    .bottom_btn {
      margin: 0 auto;
      width: 2.18rem;
      height: 0.57rem;
      background: #FF8F35;
      box-shadow: 0rem 0.01rem 0.04rem 0rem #BA580A;
      border-radius: 0.06rem;
      text-align: center;
      line-height: 0.57rem;
      font-size: 0.18rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
    }
  
    .bottom_content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 0.36rem;
      margin-top: 0.8rem;
    }
  
    .bottom_content img {
      width: 0.72rem;
      height: 0.43rem;
    }
  
    .bottom_text {
      text-align: center;
    }
  
    .bottom_text div {
      margin-left: 0.12rem;
      font-size: 0.13rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 0.18rem;
      letter-spacing: 3px;
    }
  
    .bottom_image_left {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
      width: 0.4rem;
      height: 0.59rem;
    }
  
    .bottom_image_right {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
      width: 0.82rem;
      height: 0.72rem;
    }
  
    .rocket {
      position: absolute;
      z-index: 1;
      right: -0.15rem;
      bottom: -0.05rem;
      width: 0.26rem;
      height: 0.48rem;
    }
  
    .left_top_bg {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      width: 2.05rem;
      height: 0.8rem;
    }
  
    .right_bottom_bg {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 0;
      width: 1.25rem;
      height: 0.65rem;
    }
  
    .address_container {
      position: absolute;
      width: 100%;
      z-index: 1;
    }
  
    .swiper {
      width: 100%;
      font-size: 0.12rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6B6B6B;
      line-height: 0.17rem;
      display: flex;
      justify-content: space-between;
      padding: 0 0.7rem;
      box-sizing: border-box;
      overflow: hidden;
    }
  
    .swiper_container {
      width: 1.8rem;
      text-align: center;
      overflow: hidden;
    }
    .prompt-box{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #F29347;
    }
  </style>
  <style lang="scss" scoped>
  // Variables
  $bar-color: #5badf5;
  $ball-color: #5badf5;
  $bg-color: #EA4961;
  
  html {
    height: 100%;
  }
  body {
    height: 100%;
    background: $bg-color;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .loading_title{
    font-size: 0.16rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    margin: auto;
    width: 2rem;
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #5badf5;
  }
  .loader {
    
    position: fixed;
    top: -1.5rem;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    margin: auto;
    width: 0.75rem;
    height: 1rem;
  }
  </style>
  <style scoped>
  @-webkit-keyframes opaque {
    0% {
      opacity: 0.1;
    }
  
    40% {
      opacity: 1;
    }
  
    80% {
      opacity: 0.1;
    }
  
    100% {
      opacity: 0.1;
    }
  }
  
  @keyframes opaque {
    0% {
      opacity: 0.1;
    }
  
    40% {
      opacity: 1;
    }
  
    80% {
      opacity: 0.1;
    }
  
    100% {
      opacity: 0.1;
    }
  }
  
  .ml-loader {
    position: relative;
    width: 70px;
    height: 70px;
  }
  
  .ml-loader div {
    -webkit-transform-origin: 32px 32px;
    -ms-transform-origin: 32px 32px;
    transform-origin: 32px 32px;
    -webkit-animation: 1.2s opaque ease-in-out infinite both;
    animation: 1.2s opaque ease-in-out infinite both;
  }
  
  .ml-loader div::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 30px;
    width: 5px;
    height: 18px;
    border-radius: 10px;
    background-color: #5badf5;
  }
  
  .ml-loader div:nth-child(1) {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  
  .ml-loader div:nth-child(2) {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
  
  .ml-loader div:nth-child(3) {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  
  .ml-loader div:nth-child(4) {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  
  .ml-loader div:nth-child(5) {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  
  .ml-loader div:nth-child(6) {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  
  .ml-loader div:nth-child(7) {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  
  .ml-loader div:nth-child(8) {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
  }
  
  .ml-loader div:nth-child(9) {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
  }
  
  .ml-loader div:nth-child(10) {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
  }
  
  .ml-loader div:nth-child(11) {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  
  .ml-loader div:nth-child(12) {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
    -webkit-animation-delay: 1.1s;
    animation-delay: 1.1s;
  }
  
  .ml-loader div:nth-child(13) {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
  }
  </style>